<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="permissions.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('Roles')"
    @cancel="onCancel('Roles')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'RoleCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'Roles',
      pageHeader: 'Создание роли сотрудника',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Роли сотрудников',
          route: { name: 'Roles' },
        },
        {
          text: 'Создание роли сотрудника',
        },
      ],
      initialForm: {
        name: '',
        permissions: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      permissions: (state) => state.common.permissions,
    }),

    permissionsList() {
      if (this.form?.permissions === undefined) return [];

      return this.form.permissions.map((item) => ({
        ...item,
        name: this.permissions.data.find(
          (element) => element.id === item.permission
        ).name,
      }));
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
          ],
        },
        {
          tab: 'Разрешения',
          table: {
            caption: 'Разрешения',
            headers: [
              {
                text: 'Разрешение',
                order: '',
                alias: 'name',
              },
              {
                text: '',
                order: '',
                alias: 'actions',
              },
            ],
            items: this.permissionsList,
            key: 'permissions',
            modalFields: [
              {
                type: 'select',
                key: 'permission',
                label: 'Разрешение',
                list: this.permissions.data,
              },
            ],
            showCreateButton: true,
            showDeleteButton: true,
          },
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'Permissions' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
